import React from "react"
import Layout from "../components/Layout"
import { Content, Column } from "rbx"
import { FaEnvelope } from "react-icons/fa"
import { Helmet } from "react-helmet"

export default () => (
  <Layout>
    <Helmet>
      <title>Contact</title>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
    </Helmet>
    <Content textAlign="centered">
      <Column.Group>
        <Column>
          <h1>Drop me a line</h1>
          <a href="mailto:pyramation@gmail.com" target="blank">
            <FaEnvelope size={60} />
          </a>
        </Column>
      </Column.Group>
    </Content>
  </Layout>
)
